import addresses from 'config/constants/contracts'

const chainId = process.env.REACT_APP_CHAIN_ID

export const getCakeAddress = () => {
  return addresses.cake[chainId]
}
export const getMasterChefAddress = () => {
  return addresses.masterChef[chainId]
}
export const getDiamondMasterChefAddress = () => {
  return addresses.diamondmasterChef[chainId]
}
export const getMulticallAddress = () => {
  return addresses.mulltiCall[chainId]
}
export const getWbnbAddress = () => {
  return addresses.wbnb[chainId]
}
export const getLotteryAddress = () => {
  return addresses.lottery[chainId]
}
export const getLotteryTicketAddress = () => {
  return addresses.lotteryNFT[chainId]
}
export const getForestAddress = () => {
  return addresses.forest[chainId]
}
export const getAirdropAddress = () => {
  return addresses.airdrop[chainId]
}
export const getNftAddress = () => {
  return addresses.nfts[chainId]
}
export const getDiamondAddress = () => {
  return addresses.diamond[chainId]
}
import React, { useEffect, useRef } from 'react'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import { getBalanceNumber } from 'utils/formatBalance'
import useTokenBalance from 'hooks/useTokenBalance'
import { getCakeAddress } from 'utils/addressHelpers'
import { useCountUp } from 'react-countup'

interface TokenBalanceProps {
    decimals?: number
    prefix?: string
}

const TokenBalance: React.FC<TokenBalanceProps> = ({decimals, prefix}) =>{
    const { account } = useWallet()
    const cakeBalance = getBalanceNumber(useTokenBalance(getCakeAddress()))

    const { countUp, update } = useCountUp({
        start: 0,
        end: cakeBalance,
        duration: 1,
        separator: ',',
        decimals: 
            // eslint-disable-next-line no-nested-ternary
            decimals !== undefined ? decimals : cakeBalance < 0 ? 4 : cakeBalance > 1e5 ? 0 : 3,
    })

    const updateValue = useRef(update)

    useEffect(() => {
        updateValue.current(cakeBalance)
    }, [cakeBalance, updateValue])

    return (
        account ? <>{prefix}{countUp}</> : <></>
    )
}

export default TokenBalance
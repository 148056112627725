import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import goldMasterchefABI from 'config/abi/masterchef.json'
import diamondMasterchefABI from 'config/abi/diamondMasterchef.json'
import multicall from 'utils/multicall'
import goldFarmsConfig from 'config/constants/farms'
import diamondFarmsConfig from 'config/constants/diamondFarms'
import { getMasterChefAddress, getDiamondMasterChefAddress } from 'utils/addressHelpers'

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID

export const fetchFarmUserAllowances = async (account: string) => {
  const cakeMasterChefAdress = getMasterChefAddress()
  const diamondMasterChefAdress = getDiamondMasterChefAddress()

  const calls = []
  const addFarmInToCalls = (farm, masterChefAddress) => {
    const lpContractAddress = farm.isTokenOnly ? farm.tokenAddresses[CHAIN_ID] : farm.lpAddresses[CHAIN_ID]
    calls.push({ address: lpContractAddress, name: 'allowance', params: [account, masterChefAddress] })
  }

  goldFarmsConfig.forEach((farm) => addFarmInToCalls(farm,cakeMasterChefAdress))
  diamondFarmsConfig.forEach((farm) => addFarmInToCalls(farm,diamondMasterChefAdress))

  const rawLpAllowances = await multicall(erc20ABI, calls)
  const parsedLpAllowances = rawLpAllowances.map((lpBalance) => {
    return new BigNumber(lpBalance).toJSON()
  })
  return parsedLpAllowances
}

export const fetchFarmUserTokenBalances = async (account: string) => {
  const calls = []
  const addFarmInToCalls = (farm) => {
    const lpContractAddress = farm.isTokenOnly ? farm.tokenAddresses[CHAIN_ID] : farm.lpAddresses[CHAIN_ID]
    calls.push({
      address: lpContractAddress,
      name: 'balanceOf',
      params: [account],
    })
  }

  goldFarmsConfig.forEach((farm) => addFarmInToCalls(farm))
  diamondFarmsConfig.forEach((farm) => addFarmInToCalls(farm))

  const rawTokenBalances = await multicall(erc20ABI, calls)
  const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
    return new BigNumber(tokenBalance).toJSON()
  })
  return parsedTokenBalances
}

export const fetchFarmUserStakedBalances = async (account: string) => {
  const cakeMasterChefAdress = getMasterChefAddress()
  const diamondMasterChefAdress = getDiamondMasterChefAddress()

  const goldCalls = goldFarmsConfig.map((farm) => {
    return {
      address: cakeMasterChefAdress,
      name: 'userInfo',
      params: [farm.pid, account],
    }
  })
  const diamondCalls = diamondFarmsConfig.map((farm) => {
    return {
      address: diamondMasterChefAdress,
      name: 'userInfo',
      params: [farm.pid, account],
    }
  })

  const goldRawStaked = await multicall(goldMasterchefABI, goldCalls)
  const diamondRawStaked = await multicall(diamondMasterchefABI, diamondCalls)
  const rawStaked = [...goldRawStaked, ...diamondRawStaked]
  const parsedStaked = rawStaked.map((stakedBalance) => {
    return { stackedBalance: new BigNumber(stakedBalance[0]._hex).toJSON(), nextHarvestUntil: new BigNumber(stakedBalance.nextHarvestUntil._hex).toJSON() }
  })
  return parsedStaked
}

export const fetchFarmUserEarnings = async (account: string) => {
  const cakeMasterChefAdress = getMasterChefAddress()
  const diamondMasterChefAdress = getDiamondMasterChefAddress()

  const goldCalls = goldFarmsConfig.map((farm) => {
    return {
      address: cakeMasterChefAdress,
      name: 'pendingGold',
      params: [farm.pid, account],
    }
  })
  const diamondCalls = diamondFarmsConfig.map((farm) => {
    return {
      address: diamondMasterChefAdress,
      name: 'pendingDiamond',
      params: [farm.pid, account],
    }
  });

  const goldRawEarnings = await multicall(goldMasterchefABI, goldCalls)
  const diamondRawEarnings = await multicall(diamondMasterchefABI, diamondCalls)
  const rawEarnings = [...goldRawEarnings, ...diamondRawEarnings]
  const parsedEarnings = rawEarnings.map((earnings) => {
    return new BigNumber(earnings).toJSON()
  })
  return parsedEarnings
}

import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from '@pancakeswap-libs/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'MyWebFont';
    src: url('Font.ttf') format('truetype');
  }

  * {
    font-family: 'MyWebFont';
  }

  body {
    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }
  }

  #stars {
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: 1846px 2579px #fff, 2992px 2254px #fff, 1023px 1758px #fff,
        1180px 2826px #fff, 469px 647px #fff, 234px 1180px #fff,
        2045px 1696px #fff, 2962px 3677px #fff, 585px 375px #fff,
        3007px 2376px #fff, 3944px 813px #fff, 3655px 3788px #fff,
        494px 3151px #fff, 2439px 2788px #fff, 3041px 3817px #fff,
        1938px 3233px #fff, 3001px 1108px #fff, 2091px 2704px #fff,
        3394px 1749px #fff, 90px 1989px #fff, 1535px 1093px #fff,
        2932px 915px #fff, 622px 267px #fff, 720px 3122px #fff,
        1422px 1471px #fff, 2064px 524px #fff, 2485px 1254px #fff,
        1491px 1081px #fff, 2525px 616px #fff, 608px 2319px #fff,
        835px 3918px #fff, 1053px 1522px #fff, 3063px 3566px #fff,
        3709px 3240px #fff, 2985px 3211px #fff, 1781px 246px #fff,
        3754px 1106px #fff, 3244px 2468px #fff, 1276px 1671px #fff,
        235px 1907px #fff, 2152px 2092px #fff, 3336px 3179px #fff,
        1443px 375px #fff, 2941px 2708px #fff, 2930px 228px #fff,
        951px 3376px #fff, 733px 3766px #fff, 1878px 1637px #fff,
        2492px 868px #fff, 3355px 494px #fff, 1473px 505px #fff,
        1458px 1250px #fff, 1021px 141px #fff, 3083px 3034px #fff,
        3376px 1058px #fff, 2701px 1509px #fff, 3804px 2677px #fff,
        88px 280px #fff, 1204px 356px #fff, 2182px 467px #fff, 186px 3547px #fff,
        3998px 149px #fff, 2434px 2572px #fff, 888px 2374px #fff,
        1161px 1829px #fff, 2766px 3791px #fff, 3021px 3864px #fff,
        3514px 1827px #fff, 3620px 3499px #fff, 2612px 2470px #fff,
        914px 3852px #fff, 1013px 2131px #fff, 2588px 1119px #fff,
        2373px 2224px #fff, 797px 1630px #fff, 3481px 3961px #fff,
        377px 2674px #fff, 997px 940px #fff, 2423px 580px #fff, 621px 919px #fff,
        1189px 2055px #fff, 2793px 1782px #fff, 946px 2662px #fff,
        2289px 1525px #fff, 2011px 327px #fff, 2964px 155px #fff,
        2351px 3976px #fff, 3487px 140px #fff, 1653px 2017px #fff,
        923px 1240px #fff, 2636px 2450px #fff, 135px 324px #fff,
        1602px 217px #fff, 168px 3388px #fff, 1148px 1887px #fff,
        1633px 823px #fff, 1133px 2953px #fff, 1221px 2283px #fff,
        170px 1696px #fff, 3710px 1586px #fff, 489px 3215px #fff,
        1230px 3273px #fff, 1255px 163px #fff, 2854px 2585px #fff,
        1423px 1435px #fff, 999px 882px #fff, 906px 3761px #fff,
        33px 2639px #fff, 388px 580px #fff, 465px 155px #fff, 1805px 1529px #fff,
        432px 3608px #fff, 1995px 3700px #fff, 3091px 3427px #fff,
        1125px 448px #fff, 3983px 3232px #fff, 901px 954px #fff,
        1981px 1590px #fff, 3049px 120px #fff, 3270px 2393px #fff,
        3544px 961px #fff, 2495px 1616px #fff, 1663px 1010px #fff,
        1139px 2509px #fff, 3086px 3943px #fff, 1054px 2937px #fff,
        3342px 3638px #fff, 3591px 2208px #fff, 1840px 2520px #fff,
        3651px 2905px #fff, 2281px 954px #fff, 986px 319px #fff,
        3413px 3387px #fff, 2193px 584px #fff, 364px 3916px #fff,
        3514px 420px #fff, 950px 2583px #fff, 3637px 2272px #fff,
        737px 705px #fff, 1358px 2351px #fff, 921px 1698px #fff,
        3624px 1727px #fff, 620px 444px #fff, 2386px 1725px #fff,
        3201px 3885px #fff, 704px 3105px #fff, 974px 129px #fff,
        3469px 3171px #fff, 938px 1370px #fff, 2286px 2901px #fff,
        2890px 3699px #fff, 3695px 2747px #fff, 2405px 2568px #fff,
        3099px 160px #fff, 3781px 93px #fff, 3500px 909px #fff,
        437px 2351px #fff, 1361px 2582px #fff, 3533px 634px #fff,
        3230px 1407px #fff, 1636px 720px #fff, 2473px 1732px #fff,
        19px 2965px #fff, 2686px 2696px #fff, 2716px 2547px #fff,
        514px 2256px #fff, 3617px 1166px #fff, 2060px 1124px #fff,
        2702px 2096px #fff, 943px 273px #fff, 3777px 2822px #fff,
        3481px 743px #fff, 1850px 1415px #fff, 3515px 2403px #fff,
        1840px 3393px #fff, 2911px 2469px #fff, 760px 2258px #fff,
        3052px 455px #fff, 3812px 2751px #fff, 1462px 3538px #fff,
        978px 1416px #fff, 1955px 1586px #fff, 864px 1643px #fff,
        1px 2981px #fff, 864px 2198px #fff, 870px 1476px #fff, 472px 2344px #fff,
        2025px 3880px #fff, 1101px 3216px #fff, 2155px 3517px #fff,
        1747px 919px #fff, 2301px 2772px #fff, 597px 1305px #fff,
        2868px 2515px #fff, 1572px 483px #fff, 972px 2954px #fff,
        3173px 53px #fff, 191px 547px #fff, 1669px 3301px #fff,
        449px 2879px #fff, 3730px 2630px #fff, 3442px 3610px #fff,
        3848px 3304px #fff, 2223px 1463px #fff, 3214px 2505px #fff,
        3448px 3195px #fff, 2778px 2910px #fff, 1517px 3112px #fff,
        238px 902px #fff, 2147px 2175px #fff, 1814px 1423px #fff,
        1491px 2009px #fff, 2741px 2282px #fff, 2617px 2654px #fff,
        3692px 1096px #fff, 253px 1590px #fff, 587px 873px #fff,
        858px 282px #fff, 913px 1098px #fff, 2424px 1055px #fff,
        3729px 3921px #fff, 511px 2506px #fff, 762px 3913px #fff,
        2290px 1650px #fff, 2503px 411px #fff, 476px 3494px #fff,
        3030px 2882px #fff, 2921px 3030px #fff, 3486px 3349px #fff,
        1816px 2798px #fff, 1471px 3990px #fff, 1620px 2859px #fff,
        3908px 21px #fff, 1542px 269px #fff, 3943px 3816px #fff,
        709px 2625px #fff, 2717px 1015px #fff, 1311px 801px #fff,
        2397px 2903px #fff, 1179px 1790px #fff, 1127px 2251px #fff,
        1510px 1957px #fff, 3301px 297px #fff, 1272px 481px #fff,
        2421px 1863px #fff, 1616px 2693px #fff, 2083px 2935px #fff,
        3243px 2095px #fff, 3549px 1034px #fff, 1021px 3391px #fff,
        962px 394px #fff, 1627px 1515px #fff, 1487px 1230px #fff,
        1372px 2530px #fff, 3613px 2576px #fff, 3876px 1428px #fff,
        3315px 3554px #fff, 1979px 2209px #fff, 2218px 375px #fff,
        1484px 2888px #fff, 2115px 3703px #fff, 3849px 37px #fff,
        2233px 3979px #fff, 3920px 1884px #fff, 337px 3973px #fff,
        2924px 3903px #fff, 381px 3094px #fff, 1435px 3855px #fff,
        3570px 2322px #fff, 2678px 1311px #fff, 2200px 738px #fff,
        837px 1061px #fff, 852px 1518px #fff, 1356px 689px #fff,
        852px 2915px #fff, 1822px 1610px #fff, 2867px 2518px #fff,
        2395px 2518px #fff, 1994px 1649px #fff, 52px 3875px #fff,
        1105px 3230px #fff, 3408px 248px #fff, 3659px 1689px #fff,
        1826px 1513px #fff, 3028px 3610px #fff, 1459px 2783px #fff,
        102px 3592px #fff, 2645px 3287px #fff, 2118px 3362px #fff,
        1463px 2279px #fff, 1431px 3302px #fff, 3319px 3509px #fff,
        705px 1930px #fff, 974px 813px #fff, 3708px 2616px #fff,
        388px 3117px #fff, 1252px 3059px #fff, 1673px 1074px #fff,
        2232px 1431px #fff, 228px 2767px #fff, 2611px 3107px #fff,
        841px 2859px #fff, 3853px 120px #fff, 1490px 2767px #fff,
        3039px 503px #fff, 1225px 2205px #fff, 1826px 2433px #fff,
        2887px 3863px #fff, 32px 2189px #fff, 2103px 965px #fff,
        1766px 2092px #fff, 2489px 3707px #fff, 2955px 1896px #fff,
        2263px 2998px #fff, 44px 627px #fff, 3082px 2495px #fff,
        1883px 2518px #fff, 3217px 3436px #fff, 3690px 1560px #fff,
        1806px 2187px #fff, 3266px 1020px #fff, 399px 2006px #fff,
        3949px 2291px #fff, 3265px 1627px #fff, 279px 768px #fff,
        2698px 1851px #fff, 1952px 3824px #fff, 3752px 2440px #fff,
        2576px 2561px #fff, 3764px 2570px #fff, 2917px 1639px #fff,
        1461px 1001px #fff, 704px 1188px #fff, 1341px 1232px #fff,
        464px 483px #fff, 710px 590px #fff, 2679px 455px #fff, 2819px 146px #fff,
        764px 3685px #fff, 3442px 2052px #fff, 3618px 1242px #fff,
        154px 3155px #fff, 1972px 3378px #fff, 97px 250px #fff, 81px 1972px #fff,
        2276px 3995px #fff, 3352px 3446px #fff, 462px 3621px #fff,
        3174px 1448px #fff, 2921px 1042px #fff, 3442px 579px #fff,
        3930px 2812px #fff, 2357px 3594px #fff, 3370px 2843px #fff,
        998px 3906px #fff, 3853px 875px #fff, 3491px 2157px #fff,
        1620px 3014px #fff, 1768px 3578px #fff, 2890px 2614px #fff,
        3930px 1028px #fff, 892px 1407px #fff, 2501px 3386px #fff,
        55px 1161px #fff, 386px 3078px #fff, 253px 3225px #fff,
        2859px 408px #fff, 299px 525px #fff, 1355px 2824px #fff,
        3777px 3642px #fff, 3215px 874px #fff, 1362px 3246px #fff,
        1147px 638px #fff, 3698px 2767px #fff, 2449px 1561px #fff,
        2454px 313px #fff, 519px 2559px #fff, 2724px 3347px #fff,
        3235px 270px #fff, 2131px 3376px #fff, 2972px 300px #fff,
        2732px 60px #fff, 1324px 406px #fff, 1122px 3058px #fff,
        849px 3063px #fff, 2389px 1411px #fff, 6px 1056px #fff,
        2255px 1709px #fff, 3209px 2313px #fff, 2222px 356px #fff,
        2417px 1667px #fff, 3593px 3156px #fff, 1220px 521px #fff,
        751px 2009px #fff, 1758px 2166px #fff, 991px 254px #fff,
        1753px 137px #fff, 341px 2778px #fff, 733px 2397px #fff,
        2721px 3974px #fff, 1360px 662px #fff, 2370px 3204px #fff,
        2589px 3469px #fff, 1663px 3966px #fff, 2793px 909px #fff,
        3158px 3408px #fff, 882px 3101px #fff, 768px 2586px #fff,
        1210px 1878px #fff, 722px 192px #fff, 1278px 1028px #fff,
        2064px 3658px #fff, 2920px 1436px #fff, 3214px 2039px #fff,
        2444px 3913px #fff, 1782px 1348px #fff, 381px 3698px #fff,
        2818px 3009px #fff, 690px 2269px #fff, 373px 3778px #fff,
        3727px 3594px #fff, 3992px 1311px #fff, 3854px 3429px #fff,
        2444px 972px #fff, 1715px 792px #fff, 2294px 1507px #fff, 8px 109px #fff,
        2500px 2440px #fff, 1995px 796px #fff, 2383px 2102px #fff,
        2550px 698px #fff, 1460px 2403px #fff, 3144px 1267px #fff,
        554px 2625px #fff, 3953px 30px #fff, 1286px 3764px #fff,
        54px 3059px #fff, 1143px 1648px #fff, 2755px 3837px #fff,
        2287px 2371px #fff, 666px 1292px #fff, 971px 621px #fff,
        1161px 3722px #fff, 637px 2039px #fff, 2768px 2508px #fff,
        3275px 1894px #fff, 2206px 2009px #fff, 2278px 2349px #fff,
        3691px 255px #fff, 1172px 280px #fff, 941px 3293px #fff,
        1881px 3064px #fff, 3341px 903px #fff, 2475px 2688px #fff,
        2538px 1308px #fff, 2500px 365px #fff, 3989px 310px #fff,
        1463px 2032px #fff, 754px 2905px #fff, 365px 1819px #fff,
        1202px 2562px #fff, 2622px 506px #fff, 403px 1615px #fff,
        3016px 236px #fff, 738px 3437px #fff, 1731px 1853px #fff,
        3999px 1069px #fff, 1997px 678px #fff, 2571px 2373px #fff,
        529px 2129px #fff, 2321px 424px #fff, 298px 3249px #fff,
        542px 661px #fff, 1210px 2262px #fff, 963px 388px #fff,
        3320px 2504px #fff, 3879px 2123px #fff, 2423px 1386px #fff,
        3092px 1042px #fff, 429px 3589px #fff, 1190px 355px #fff,
        2276px 2788px #fff, 2269px 3697px #fff, 900px 2911px #fff,
        3740px 3265px #fff, 3571px 3394px #fff, 2033px 3920px #fff,
        1616px 2629px #fff, 810px 35px #fff, 173px 81px #fff, 2965px 3469px #fff,
        2362px 1776px #fff, 595px 1236px #fff, 2349px 1949px #fff,
        3371px 937px #fff, 536px 1802px #fff, 159px 2909px #fff,
        650px 3708px #fff, 3351px 744px #fff, 656px 1455px #fff,
        1200px 1181px #fff, 3449px 3481px #fff, 3099px 1913px #fff,
        1288px 802px #fff, 3966px 2403px #fff, 3098px 3721px #fff,
        1067px 2834px #fff, 3205px 2716px #fff, 69px 3417px #fff,
        423px 2813px #fff, 2593px 2319px #fff, 2773px 352px #fff,
        2913px 1608px #fff, 1950px 2721px #fff, 1088px 2176px #fff,
        2167px 1025px #fff, 1411px 1433px #fff, 1976px 3848px #fff,
        2734px 2599px #fff, 2459px 2232px #fff, 2802px 3313px #fff,
        1942px 1698px #fff, 1371px 431px #fff, 2078px 3034px #fff,
        2189px 1131px #fff, 2200px 1876px #fff, 1626px 1735px #fff,
        1187px 444px #fff, 2026px 2927px #fff, 2810px 190px #fff,
        2306px 946px #fff, 1552px 44px #fff, 2548px 1703px #fff,
        310px 844px #fff, 2674px 1804px #fff, 692px 875px #fff,
        2424px 801px #fff, 3163px 1912px #fff, 1654px 2026px #fff,
        2863px 2010px #fff, 1789px 1375px #fff, 2571px 1962px #fff,
        3556px 3046px #fff, 2364px 3946px #fff, 3660px 3744px #fff,
        2400px 1709px #fff, 1337px 2663px #fff, 353px 788px #fff,
        554px 2360px #fff, 3752px 1697px #fff, 1277px 512px #fff,
        3023px 76px #fff, 13px 2073px #fff, 1435px 2840px #fff,
        114px 2331px #fff, 947px 1161px #fff, 2091px 3547px #fff,
        405px 2200px #fff, 3531px 3891px #fff, 959px 60px #fff,
        2468px 3767px #fff, 701px 574px #fff, 3150px 2347px #fff,
        3600px 3655px #fff, 2805px 260px #fff, 1797px 3615px #fff,
        1746px 948px #fff, 1480px 3406px #fff, 2202px 1520px #fff,
        3394px 2983px #fff, 1722px 2426px #fff, 1383px 13px #fff,
        2945px 124px #fff, 1243px 247px #fff, 2313px 103px #fff,
        1369px 210px #fff, 347px 402px #fff, 1690px 247px #fff,
        2207px 405px #fff, 3230px 1989px #fff, 1519px 1149px #fff,
        2091px 2230px #fff, 3085px 177px #fff, 40px 2771px #fff,
        3041px 3341px #fff, 2111px 2643px #fff, 1459px 242px #fff,
        2235px 2264px #fff, 3657px 2903px #fff, 967px 1041px #fff,
        343px 3714px #fff, 127px 1078px #fff, 1114px 3981px #fff,
        2437px 665px #fff, 3842px 2966px #fff, 317px 3988px #fff,
        3631px 3050px #fff, 3707px 1608px #fff, 191px 2419px #fff,
        2398px 2997px #fff, 750px 2771px #fff, 1536px 476px #fff,
        988px 2538px #fff, 3032px 1451px #fff, 522px 614px #fff,
        2102px 2829px #fff, 122px 266px #fff, 3663px 1700px #fff,
        582px 2395px #fff, 372px 1025px #fff, 356px 2083px #fff,
        2608px 1960px #fff, 1015px 3649px #fff, 2578px 3599px #fff,
        1104px 2023px #fff, 3112px 3511px #fff, 3666px 2240px #fff,
        2215px 1011px #fff, 2782px 3302px #fff, 3039px 1222px #fff,
        1324px 2825px #fff, 3943px 2656px #fff, 609px 2143px #fff,
        1271px 3731px #fff, 2567px 2793px #fff, 1175px 1569px #fff,
        298px 340px #fff, 2968px 1163px #fff, 1449px 951px #fff,
        2120px 786px #fff, 1352px 3215px #fff, 1307px 1926px #fff,
        841px 2893px #fff, 2112px 1824px #fff, 682px 2809px #fff,
        3602px 399px #fff, 3610px 2248px #fff, 1737px 1380px #fff,
        3451px 1845px #fff, 3718px 3350px #fff, 121px 2446px #fff,
        2273px 1487px #fff, 3383px 753px #fff, 1323px 3695px #fff,
        2031px 2971px #fff, 3958px 3847px #fff, 12px 3924px #fff,
        833px 1575px #fff, 2906px 733px #fff, 3702px 1021px #fff,
        2536px 1573px #fff, 1568px 2639px #fff, 1504px 3937px #fff,
        3242px 1959px #fff, 3977px 3013px #fff, 1360px 3008px #fff,
        62px 2380px #fff, 730px 2284px #fff, 1846px 2201px #fff,
        1069px 485px #fff, 2382px 1828px #fff, 3384px 2336px #fff,
        3808px 1131px #fff, 2197px 2502px #fff, 767px 486px #fff,
        2519px 1094px #fff, 3719px 3268px #fff, 1234px 166px #fff,
        3127px 2918px #fff, 1342px 3797px #fff, 2572px 1380px #fff,
        2277px 330px #fff, 1702px 2540px #fff, 788px 1885px #fff,
        703px 65px #fff, 1608px 250px #fff, 26px 260px #fff, 1853px 3912px #fff,
        1654px 3350px #fff, 13px 1688px #fff, 1387px 1453px #fff,
        3939px 97px #fff, 435px 2893px #fff, 648px 1271px #fff, 830px 987px #fff,
        298px 2732px #fff, 1575px 906px #fff, 2175px 1695px #fff,
        2168px 3703px #fff, 2609px 2769px #fff, 1595px 1535px #fff,
        929px 230px #fff, 2591px 2867px #fff, 3274px 1356px #fff,
        2523px 2889px #fff, 3202px 3482px #fff, 3324px 720px #fff,
        2834px 407px #fff, 3412px 3267px #fff, 778px 1108px #fff;
    animation: animStar 50s linear infinite;
    position: relative;
    z-index: 2;
}
#stars:after {
    content: " ";
    position: absolute;
    top: 1000px;
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: 1846px 2579px #fff, 2992px 2254px #fff, 1023px 1758px #fff,
        1180px 2826px #fff, 469px 647px #fff, 234px 1180px #fff,
        2045px 1696px #fff, 2962px 3677px #fff, 585px 375px #fff,
        3007px 2376px #fff, 3944px 813px #fff, 3655px 3788px #fff,
        494px 3151px #fff, 2439px 2788px #fff, 3041px 3817px #fff,
        1938px 3233px #fff, 3001px 1108px #fff, 2091px 2704px #fff,
        3394px 1749px #fff, 90px 1989px #fff, 1535px 1093px #fff,
        2932px 915px #fff, 622px 267px #fff, 720px 3122px #fff,
        1422px 1471px #fff, 2064px 524px #fff, 2485px 1254px #fff,
        1491px 1081px #fff, 2525px 616px #fff, 608px 2319px #fff,
        835px 3918px #fff, 1053px 1522px #fff, 3063px 3566px #fff,
        3709px 3240px #fff, 2985px 3211px #fff, 1781px 246px #fff,
        3754px 1106px #fff, 3244px 2468px #fff, 1276px 1671px #fff,
        235px 1907px #fff, 2152px 2092px #fff, 3336px 3179px #fff,
        1443px 375px #fff, 2941px 2708px #fff, 2930px 228px #fff,
        951px 3376px #fff, 733px 3766px #fff, 1878px 1637px #fff,
        2492px 868px #fff, 3355px 494px #fff, 1473px 505px #fff,
        1458px 1250px #fff, 1021px 141px #fff, 3083px 3034px #fff,
        3376px 1058px #fff, 2701px 1509px #fff, 3804px 2677px #fff,
        88px 280px #fff, 1204px 356px #fff, 2182px 467px #fff, 186px 3547px #fff,
        3998px 149px #fff, 2434px 2572px #fff, 888px 2374px #fff,
        1161px 1829px #fff, 2766px 3791px #fff, 3021px 3864px #fff,
        3514px 1827px #fff, 3620px 3499px #fff, 2612px 2470px #fff,
        914px 3852px #fff, 1013px 2131px #fff, 2588px 1119px #fff,
        2373px 2224px #fff, 797px 1630px #fff, 3481px 3961px #fff,
        377px 2674px #fff, 997px 940px #fff, 2423px 580px #fff, 621px 919px #fff,
        1189px 2055px #fff, 2793px 1782px #fff, 946px 2662px #fff,
        2289px 1525px #fff, 2011px 327px #fff, 2964px 155px #fff,
        2351px 3976px #fff, 3487px 140px #fff, 1653px 2017px #fff,
        923px 1240px #fff, 2636px 2450px #fff, 135px 324px #fff,
        1602px 217px #fff, 168px 3388px #fff, 1148px 1887px #fff,
        1633px 823px #fff, 1133px 2953px #fff, 1221px 2283px #fff,
        170px 1696px #fff, 3710px 1586px #fff, 489px 3215px #fff,
        1230px 3273px #fff, 1255px 163px #fff, 2854px 2585px #fff,
        1423px 1435px #fff, 999px 882px #fff, 906px 3761px #fff,
        33px 2639px #fff, 388px 580px #fff, 465px 155px #fff, 1805px 1529px #fff,
        432px 3608px #fff, 1995px 3700px #fff, 3091px 3427px #fff,
        1125px 448px #fff, 3983px 3232px #fff, 901px 954px #fff,
        1981px 1590px #fff, 3049px 120px #fff, 3270px 2393px #fff,
        3544px 961px #fff, 2495px 1616px #fff, 1663px 1010px #fff,
        1139px 2509px #fff, 3086px 3943px #fff, 1054px 2937px #fff,
        3342px 3638px #fff, 3591px 2208px #fff, 1840px 2520px #fff,
        3651px 2905px #fff, 2281px 954px #fff, 986px 319px #fff,
        3413px 3387px #fff, 2193px 584px #fff, 364px 3916px #fff,
        3514px 420px #fff, 950px 2583px #fff, 3637px 2272px #fff,
        737px 705px #fff, 1358px 2351px #fff, 921px 1698px #fff,
        3624px 1727px #fff, 620px 444px #fff, 2386px 1725px #fff,
        3201px 3885px #fff, 704px 3105px #fff, 974px 129px #fff,
        3469px 3171px #fff, 938px 1370px #fff, 2286px 2901px #fff,
        2890px 3699px #fff, 3695px 2747px #fff, 2405px 2568px #fff,
        3099px 160px #fff, 3781px 93px #fff, 3500px 909px #fff,
        437px 2351px #fff, 1361px 2582px #fff, 3533px 634px #fff,
        3230px 1407px #fff, 1636px 720px #fff, 2473px 1732px #fff,
        19px 2965px #fff, 2686px 2696px #fff, 2716px 2547px #fff,
        514px 2256px #fff, 3617px 1166px #fff, 2060px 1124px #fff,
        2702px 2096px #fff, 943px 273px #fff, 3777px 2822px #fff,
        3481px 743px #fff, 1850px 1415px #fff, 3515px 2403px #fff,
        1840px 3393px #fff, 2911px 2469px #fff, 760px 2258px #fff,
        3052px 455px #fff, 3812px 2751px #fff, 1462px 3538px #fff,
        978px 1416px #fff, 1955px 1586px #fff, 864px 1643px #fff,
        1px 2981px #fff, 864px 2198px #fff, 870px 1476px #fff, 472px 2344px #fff,
        2025px 3880px #fff, 1101px 3216px #fff, 2155px 3517px #fff,
        1747px 919px #fff, 2301px 2772px #fff, 597px 1305px #fff,
        2868px 2515px #fff, 1572px 483px #fff, 972px 2954px #fff,
        3173px 53px #fff, 191px 547px #fff, 1669px 3301px #fff,
        449px 2879px #fff, 3730px 2630px #fff, 3442px 3610px #fff,
        3848px 3304px #fff, 2223px 1463px #fff, 3214px 2505px #fff,
        3448px 3195px #fff, 2778px 2910px #fff, 1517px 3112px #fff,
        238px 902px #fff, 2147px 2175px #fff, 1814px 1423px #fff,
        1491px 2009px #fff, 2741px 2282px #fff, 2617px 2654px #fff,
        3692px 1096px #fff, 253px 1590px #fff, 587px 873px #fff,
        858px 282px #fff, 913px 1098px #fff, 2424px 1055px #fff,
        3729px 3921px #fff, 511px 2506px #fff, 762px 3913px #fff,
        2290px 1650px #fff, 2503px 411px #fff, 476px 3494px #fff,
        3030px 2882px #fff, 2921px 3030px #fff, 3486px 3349px #fff,
        1816px 2798px #fff, 1471px 3990px #fff, 1620px 2859px #fff,
        3908px 21px #fff, 1542px 269px #fff, 3943px 3816px #fff,
        709px 2625px #fff, 2717px 1015px #fff, 1311px 801px #fff,
        2397px 2903px #fff, 1179px 1790px #fff, 1127px 2251px #fff,
        1510px 1957px #fff, 3301px 297px #fff, 1272px 481px #fff,
        2421px 1863px #fff, 1616px 2693px #fff, 2083px 2935px #fff,
        3243px 2095px #fff, 3549px 1034px #fff, 1021px 3391px #fff,
        962px 394px #fff, 1627px 1515px #fff, 1487px 1230px #fff,
        1372px 2530px #fff, 3613px 2576px #fff, 3876px 1428px #fff,
        3315px 3554px #fff, 1979px 2209px #fff, 2218px 375px #fff,
        1484px 2888px #fff, 2115px 3703px #fff, 3849px 37px #fff,
        2233px 3979px #fff, 3920px 1884px #fff, 337px 3973px #fff,
        2924px 3903px #fff, 381px 3094px #fff, 1435px 3855px #fff,
        3570px 2322px #fff, 2678px 1311px #fff, 2200px 738px #fff,
        837px 1061px #fff, 852px 1518px #fff, 1356px 689px #fff,
        852px 2915px #fff, 1822px 1610px #fff, 2867px 2518px #fff,
        2395px 2518px #fff, 1994px 1649px #fff, 52px 3875px #fff,
        1105px 3230px #fff, 3408px 248px #fff, 3659px 1689px #fff,
        1826px 1513px #fff, 3028px 3610px #fff, 1459px 2783px #fff,
        102px 3592px #fff, 2645px 3287px #fff, 2118px 3362px #fff,
        1463px 2279px #fff, 1431px 3302px #fff, 3319px 3509px #fff,
        705px 1930px #fff, 974px 813px #fff, 3708px 2616px #fff,
        388px 3117px #fff, 1252px 3059px #fff, 1673px 1074px #fff,
        2232px 1431px #fff, 228px 2767px #fff, 2611px 3107px #fff,
        841px 2859px #fff, 3853px 120px #fff, 1490px 2767px #fff,
        3039px 503px #fff, 1225px 2205px #fff, 1826px 2433px #fff,
        2887px 3863px #fff, 32px 2189px #fff, 2103px 965px #fff,
        1766px 2092px #fff, 2489px 3707px #fff, 2955px 1896px #fff,
        2263px 2998px #fff, 44px 627px #fff, 3082px 2495px #fff,
        1883px 2518px #fff, 3217px 3436px #fff, 3690px 1560px #fff,
        1806px 2187px #fff, 3266px 1020px #fff, 399px 2006px #fff,
        3949px 2291px #fff, 3265px 1627px #fff, 279px 768px #fff,
        2698px 1851px #fff, 1952px 3824px #fff, 3752px 2440px #fff,
        2576px 2561px #fff, 3764px 2570px #fff, 2917px 1639px #fff,
        1461px 1001px #fff, 704px 1188px #fff, 1341px 1232px #fff,
        464px 483px #fff, 710px 590px #fff, 2679px 455px #fff, 2819px 146px #fff,
        764px 3685px #fff, 3442px 2052px #fff, 3618px 1242px #fff,
        154px 3155px #fff, 1972px 3378px #fff, 97px 250px #fff, 81px 1972px #fff,
        2276px 3995px #fff, 3352px 3446px #fff, 462px 3621px #fff,
        3174px 1448px #fff, 2921px 1042px #fff, 3442px 579px #fff,
        3930px 2812px #fff, 2357px 3594px #fff, 3370px 2843px #fff,
        998px 3906px #fff, 3853px 875px #fff, 3491px 2157px #fff,
        1620px 3014px #fff, 1768px 3578px #fff, 2890px 2614px #fff,
        3930px 1028px #fff, 892px 1407px #fff, 2501px 3386px #fff,
        55px 1161px #fff, 386px 3078px #fff, 253px 3225px #fff,
        2859px 408px #fff, 299px 525px #fff, 1355px 2824px #fff,
        3777px 3642px #fff, 3215px 874px #fff, 1362px 3246px #fff,
        1147px 638px #fff, 3698px 2767px #fff, 2449px 1561px #fff,
        2454px 313px #fff, 519px 2559px #fff, 2724px 3347px #fff,
        3235px 270px #fff, 2131px 3376px #fff, 2972px 300px #fff,
        2732px 60px #fff, 1324px 406px #fff, 1122px 3058px #fff,
        849px 3063px #fff, 2389px 1411px #fff, 6px 1056px #fff,
        2255px 1709px #fff, 3209px 2313px #fff, 2222px 356px #fff,
        2417px 1667px #fff, 3593px 3156px #fff, 1220px 521px #fff,
        751px 2009px #fff, 1758px 2166px #fff, 991px 254px #fff,
        1753px 137px #fff, 341px 2778px #fff, 733px 2397px #fff,
        2721px 3974px #fff, 1360px 662px #fff, 2370px 3204px #fff,
        2589px 3469px #fff, 1663px 3966px #fff, 2793px 909px #fff,
        3158px 3408px #fff, 882px 3101px #fff, 768px 2586px #fff,
        1210px 1878px #fff, 722px 192px #fff, 1278px 1028px #fff,
        2064px 3658px #fff, 2920px 1436px #fff, 3214px 2039px #fff,
        2444px 3913px #fff, 1782px 1348px #fff, 381px 3698px #fff,
        2818px 3009px #fff, 690px 2269px #fff, 373px 3778px #fff,
        3727px 3594px #fff, 3992px 1311px #fff, 3854px 3429px #fff,
        2444px 972px #fff, 1715px 792px #fff, 2294px 1507px #fff, 8px 109px #fff,
        2500px 2440px #fff, 1995px 796px #fff, 2383px 2102px #fff,
        2550px 698px #fff, 1460px 2403px #fff, 3144px 1267px #fff,
        554px 2625px #fff, 3953px 30px #fff, 1286px 3764px #fff,
        54px 3059px #fff, 1143px 1648px #fff, 2755px 3837px #fff,
        2287px 2371px #fff, 666px 1292px #fff, 971px 621px #fff,
        1161px 3722px #fff, 637px 2039px #fff, 2768px 2508px #fff,
        3275px 1894px #fff, 2206px 2009px #fff, 2278px 2349px #fff,
        3691px 255px #fff, 1172px 280px #fff, 941px 3293px #fff,
        1881px 3064px #fff, 3341px 903px #fff, 2475px 2688px #fff,
        2538px 1308px #fff, 2500px 365px #fff, 3989px 310px #fff,
        1463px 2032px #fff, 754px 2905px #fff, 365px 1819px #fff,
        1202px 2562px #fff, 2622px 506px #fff, 403px 1615px #fff,
        3016px 236px #fff, 738px 3437px #fff, 1731px 1853px #fff,
        3999px 1069px #fff, 1997px 678px #fff, 2571px 2373px #fff,
        529px 2129px #fff, 2321px 424px #fff, 298px 3249px #fff,
        542px 661px #fff, 1210px 2262px #fff, 963px 388px #fff,
        3320px 2504px #fff, 3879px 2123px #fff, 2423px 1386px #fff,
        3092px 1042px #fff, 429px 3589px #fff, 1190px 355px #fff,
        2276px 2788px #fff, 2269px 3697px #fff, 900px 2911px #fff,
        3740px 3265px #fff, 3571px 3394px #fff, 2033px 3920px #fff,
        1616px 2629px #fff, 810px 35px #fff, 173px 81px #fff, 2965px 3469px #fff,
        2362px 1776px #fff, 595px 1236px #fff, 2349px 1949px #fff,
        3371px 937px #fff, 536px 1802px #fff, 159px 2909px #fff,
        650px 3708px #fff, 3351px 744px #fff, 656px 1455px #fff,
        1200px 1181px #fff, 3449px 3481px #fff, 3099px 1913px #fff,
        1288px 802px #fff, 3966px 2403px #fff, 3098px 3721px #fff,
        1067px 2834px #fff, 3205px 2716px #fff, 69px 3417px #fff,
        423px 2813px #fff, 2593px 2319px #fff, 2773px 352px #fff,
        2913px 1608px #fff, 1950px 2721px #fff, 1088px 2176px #fff,
        2167px 1025px #fff, 1411px 1433px #fff, 1976px 3848px #fff,
        2734px 2599px #fff, 2459px 2232px #fff, 2802px 3313px #fff,
        1942px 1698px #fff, 1371px 431px #fff, 2078px 3034px #fff,
        2189px 1131px #fff, 2200px 1876px #fff, 1626px 1735px #fff,
        1187px 444px #fff, 2026px 2927px #fff, 2810px 190px #fff,
        2306px 946px #fff, 1552px 44px #fff, 2548px 1703px #fff,
        310px 844px #fff, 2674px 1804px #fff, 692px 875px #fff,
        2424px 801px #fff, 3163px 1912px #fff, 1654px 2026px #fff,
        2863px 2010px #fff, 1789px 1375px #fff, 2571px 1962px #fff,
        3556px 3046px #fff, 2364px 3946px #fff, 3660px 3744px #fff,
        2400px 1709px #fff, 1337px 2663px #fff, 353px 788px #fff,
        554px 2360px #fff, 3752px 1697px #fff, 1277px 512px #fff,
        3023px 76px #fff, 13px 2073px #fff, 1435px 2840px #fff,
        114px 2331px #fff, 947px 1161px #fff, 2091px 3547px #fff,
        405px 2200px #fff, 3531px 3891px #fff, 959px 60px #fff,
        2468px 3767px #fff, 701px 574px #fff, 3150px 2347px #fff,
        3600px 3655px #fff, 2805px 260px #fff, 1797px 3615px #fff,
        1746px 948px #fff, 1480px 3406px #fff, 2202px 1520px #fff,
        3394px 2983px #fff, 1722px 2426px #fff, 1383px 13px #fff,
        2945px 124px #fff, 1243px 247px #fff, 2313px 103px #fff,
        1369px 210px #fff, 347px 402px #fff, 1690px 247px #fff,
        2207px 405px #fff, 3230px 1989px #fff, 1519px 1149px #fff,
        2091px 2230px #fff, 3085px 177px #fff, 40px 2771px #fff,
        3041px 3341px #fff, 2111px 2643px #fff, 1459px 242px #fff,
        2235px 2264px #fff, 3657px 2903px #fff, 967px 1041px #fff,
        343px 3714px #fff, 127px 1078px #fff, 1114px 3981px #fff,
        2437px 665px #fff, 3842px 2966px #fff, 317px 3988px #fff,
        3631px 3050px #fff, 3707px 1608px #fff, 191px 2419px #fff,
        2398px 2997px #fff, 750px 2771px #fff, 1536px 476px #fff,
        988px 2538px #fff, 3032px 1451px #fff, 522px 614px #fff,
        2102px 2829px #fff, 122px 266px #fff, 3663px 1700px #fff,
        582px 2395px #fff, 372px 1025px #fff, 356px 2083px #fff,
        2608px 1960px #fff, 1015px 3649px #fff, 2578px 3599px #fff,
        1104px 2023px #fff, 3112px 3511px #fff, 3666px 2240px #fff,
        2215px 1011px #fff, 2782px 3302px #fff, 3039px 1222px #fff,
        1324px 2825px #fff, 3943px 2656px #fff, 609px 2143px #fff,
        1271px 3731px #fff, 2567px 2793px #fff, 1175px 1569px #fff,
        298px 340px #fff, 2968px 1163px #fff, 1449px 951px #fff,
        2120px 786px #fff, 1352px 3215px #fff, 1307px 1926px #fff,
        841px 2893px #fff, 2112px 1824px #fff, 682px 2809px #fff,
        3602px 399px #fff, 3610px 2248px #fff, 1737px 1380px #fff,
        3451px 1845px #fff, 3718px 3350px #fff, 121px 2446px #fff,
        2273px 1487px #fff, 3383px 753px #fff, 1323px 3695px #fff,
        2031px 2971px #fff, 3958px 3847px #fff, 12px 3924px #fff,
        833px 1575px #fff, 2906px 733px #fff, 3702px 1021px #fff,
        2536px 1573px #fff, 1568px 2639px #fff, 1504px 3937px #fff,
        3242px 1959px #fff, 3977px 3013px #fff, 1360px 3008px #fff,
        62px 2380px #fff, 730px 2284px #fff, 1846px 2201px #fff,
        1069px 485px #fff, 2382px 1828px #fff, 3384px 2336px #fff,
        3808px 1131px #fff, 2197px 2502px #fff, 767px 486px #fff,
        2519px 1094px #fff, 3719px 3268px #fff, 1234px 166px #fff,
        3127px 2918px #fff, 1342px 3797px #fff, 2572px 1380px #fff,
        2277px 330px #fff, 1702px 2540px #fff, 788px 1885px #fff,
        703px 65px #fff, 1608px 250px #fff, 26px 260px #fff, 1853px 3912px #fff,
        1654px 3350px #fff, 13px 1688px #fff, 1387px 1453px #fff,
        3939px 97px #fff, 435px 2893px #fff, 648px 1271px #fff, 830px 987px #fff,
        298px 2732px #fff, 1575px 906px #fff, 2175px 1695px #fff,
        2168px 3703px #fff, 2609px 2769px #fff, 1595px 1535px #fff,
        929px 230px #fff, 2591px 2867px #fff, 3274px 1356px #fff,
        2523px 2889px #fff, 3202px 3482px #fff, 3324px 720px #fff,
        2834px 407px #fff, 3412px 3267px #fff, 778px 1108px #fff;
}
#stars2 {
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: 3891px 1755px #fff, 252px 2074px #fff, 224px 3276px #fff,
        2703px 1038px #fff, 3085px 365px #fff, 2301px 3813px #fff,
        3133px 3576px #fff, 3405px 2440px #fff, 2312px 3953px #fff,
        1895px 1094px #fff, 811px 1954px #fff, 464px 3134px #fff,
        1893px 1948px #fff, 1825px 3696px #fff, 1955px 2541px #fff,
        261px 560px #fff, 2475px 3663px #fff, 747px 2627px #fff,
        1174px 3620px #fff, 1866px 929px #fff, 2468px 1890px #fff,
        3791px 2911px #fff, 2039px 1184px #fff, 2750px 1709px #fff,
        2528px 3699px #fff, 1090px 2381px #fff, 3664px 3733px #fff,
        1786px 1466px #fff, 540px 273px #fff, 2697px 737px #fff,
        3763px 2203px #fff, 1326px 1370px #fff, 2188px 2713px #fff,
        2973px 2248px #fff, 503px 3812px #fff, 136px 2531px #fff,
        249px 1897px #fff, 399px 3364px #fff, 535px 1976px #fff,
        277px 3873px #fff, 646px 2921px #fff, 29px 1390px #fff,
        1504px 2963px #fff, 2193px 673px #fff, 69px 824px #fff,
        3380px 3447px #fff, 3087px 1299px #fff, 1442px 982px #fff,
        921px 2110px #fff, 3740px 2948px #fff, 1567px 2808px #fff,
        3045px 2713px #fff, 2631px 2238px #fff, 3763px 2848px #fff,
        323px 584px #fff, 1214px 791px #fff, 691px 530px #fff, 2609px 334px #fff,
        3717px 1339px #fff, 69px 1776px #fff, 3539px 978px #fff,
        1029px 3312px #fff, 3722px 2300px #fff, 3604px 3475px #fff,
        3372px 3343px #fff, 541px 3639px #fff, 3224px 1869px #fff,
        2531px 2518px #fff, 3073px 479px #fff, 2962px 277px #fff,
        2525px 2082px #fff, 3351px 1213px #fff, 2873px 266px #fff,
        1600px 198px #fff, 2238px 738px #fff, 3043px 572px #fff,
        1605px 538px #fff, 21px 3232px #fff, 1640px 3893px #fff,
        3862px 2848px #fff, 393px 744px #fff, 2517px 689px #fff,
        1942px 2078px #fff, 1974px 2206px #fff, 639px 3319px #fff,
        1810px 1643px #fff, 15px 3346px #fff, 3993px 2580px #fff,
        532px 954px #fff, 1102px 3918px #fff, 1754px 3673px #fff,
        3076px 866px #fff, 45px 1888px #fff, 2900px 1492px #fff,
        1918px 3335px #fff, 719px 1010px #fff, 2890px 1499px #fff,
        3720px 907px #fff, 1937px 2599px #fff, 2894px 1942px #fff,
        2854px 3368px #fff, 1842px 413px #fff, 879px 2499px #fff,
        2766px 3108px #fff, 3463px 3804px #fff, 2151px 69px #fff,
        2843px 1869px #fff, 240px 1681px #fff, 2497px 3491px #fff,
        2451px 2010px #fff, 1162px 3711px #fff, 2936px 3851px #fff,
        872px 683px #fff, 524px 1372px #fff, 3514px 3334px #fff,
        1539px 3781px #fff, 2117px 1088px #fff, 611px 2020px #fff,
        1651px 791px #fff, 2979px 196px #fff, 3034px 702px #fff,
        2330px 2366px #fff, 730px 2552px #fff, 3037px 2321px #fff,
        634px 21px #fff, 3620px 3471px #fff, 369px 3462px #fff,
        3101px 246px #fff, 1060px 1450px #fff, 919px 2327px #fff,
        2315px 527px #fff, 2364px 1143px #fff, 1710px 2351px #fff,
        138px 2887px #fff, 2793px 2212px #fff, 3130px 1151px #fff,
        2389px 1864px #fff, 1043px 2208px #fff, 1827px 2423px #fff,
        3226px 2075px #fff, 2111px 2775px #fff, 1069px 1058px #fff,
        1802px 170px #fff, 1358px 1409px #fff, 1512px 293px #fff,
        2566px 426px #fff, 3693px 2151px #fff, 1173px 1008px #fff,
        1919px 2668px #fff, 3813px 684px #fff, 2643px 1487px #fff,
        834px 1048px #fff, 930px 87px #fff, 938px 2899px #fff, 732px 3826px #fff,
        2619px 86px #fff, 3481px 453px #fff, 1111px 763px #fff,
        2509px 2198px #fff, 3836px 2066px #fff, 3936px 3196px #fff,
        694px 2011px #fff, 3122px 1874px #fff, 1724px 1889px #fff,
        2360px 3922px #fff, 2590px 2416px #fff, 2683px 3729px #fff,
        832px 767px #fff, 1011px 2555px #fff, 3283px 1759px #fff,
        1618px 744px #fff, 3118px 1467px #fff, 1216px 3695px #fff,
        3490px 616px #fff, 3060px 2156px #fff, 2526px 1136px #fff,
        566px 3235px #fff, 960px 2999px #fff, 539px 485px #fff,
        883px 3048px #fff, 2317px 2740px #fff, 3132px 2617px #fff,
        3893px 532px #fff, 351px 3866px #fff, 1541px 2388px #fff,
        3393px 801px #fff, 2601px 3780px #fff, 806px 1689px #fff,
        2343px 1610px #fff, 1674px 2587px #fff, 824px 1973px #fff,
        976px 113px #fff, 853px 3107px #fff, 1575px 2722px #fff,
        2637px 1166px #fff, 632px 3934px #fff, 1997px 2741px #fff,
        2410px 1357px #fff, 2526px 3899px #fff, 1814px 3033px #fff;
    animation: animStar 100s linear infinite;
    position: relative;
    z-index: 2;
}
#stars2:after {
    content: " ";
    position: absolute;
    top: 1000px;
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: 3891px 1755px #fff, 252px 2074px #fff, 224px 3276px #fff,
        2703px 1038px #fff, 3085px 365px #fff, 2301px 3813px #fff,
        3133px 3576px #fff, 3405px 2440px #fff, 2312px 3953px #fff,
        1895px 1094px #fff, 811px 1954px #fff, 464px 3134px #fff,
        1893px 1948px #fff, 1825px 3696px #fff, 1955px 2541px #fff,
        261px 560px #fff, 2475px 3663px #fff, 747px 2627px #fff,
        1174px 3620px #fff, 1866px 929px #fff, 2468px 1890px #fff,
        3791px 2911px #fff, 2039px 1184px #fff, 2750px 1709px #fff,
        2528px 3699px #fff, 1090px 2381px #fff, 3664px 3733px #fff,
        1786px 1466px #fff, 540px 273px #fff, 2697px 737px #fff,
        3763px 2203px #fff, 1326px 1370px #fff, 2188px 2713px #fff,
        2973px 2248px #fff, 503px 3812px #fff, 136px 2531px #fff,
        249px 1897px #fff, 399px 3364px #fff, 535px 1976px #fff,
        277px 3873px #fff, 646px 2921px #fff, 29px 1390px #fff,
        1504px 2963px #fff, 2193px 673px #fff, 69px 824px #fff,
        3380px 3447px #fff, 3087px 1299px #fff, 1442px 982px #fff,
        921px 2110px #fff, 3740px 2948px #fff, 1567px 2808px #fff,
        3045px 2713px #fff, 2631px 2238px #fff, 3763px 2848px #fff,
        323px 584px #fff, 1214px 791px #fff, 691px 530px #fff, 2609px 334px #fff,
        3717px 1339px #fff, 69px 1776px #fff, 3539px 978px #fff,
        1029px 3312px #fff, 3722px 2300px #fff, 3604px 3475px #fff,
        3372px 3343px #fff, 541px 3639px #fff, 3224px 1869px #fff,
        2531px 2518px #fff, 3073px 479px #fff, 2962px 277px #fff,
        2525px 2082px #fff, 3351px 1213px #fff, 2873px 266px #fff,
        1600px 198px #fff, 2238px 738px #fff, 3043px 572px #fff,
        1605px 538px #fff, 21px 3232px #fff, 1640px 3893px #fff,
        3862px 2848px #fff, 393px 744px #fff, 2517px 689px #fff,
        1942px 2078px #fff, 1974px 2206px #fff, 639px 3319px #fff,
        1810px 1643px #fff, 15px 3346px #fff, 3993px 2580px #fff,
        532px 954px #fff, 1102px 3918px #fff, 1754px 3673px #fff,
        3076px 866px #fff, 45px 1888px #fff, 2900px 1492px #fff,
        1918px 3335px #fff, 719px 1010px #fff, 2890px 1499px #fff,
        3720px 907px #fff, 1937px 2599px #fff, 2894px 1942px #fff,
        2854px 3368px #fff, 1842px 413px #fff, 879px 2499px #fff,
        2766px 3108px #fff, 3463px 3804px #fff, 2151px 69px #fff,
        2843px 1869px #fff, 240px 1681px #fff, 2497px 3491px #fff,
        2451px 2010px #fff, 1162px 3711px #fff, 2936px 3851px #fff,
        872px 683px #fff, 524px 1372px #fff, 3514px 3334px #fff,
        1539px 3781px #fff, 2117px 1088px #fff, 611px 2020px #fff,
        1651px 791px #fff, 2979px 196px #fff, 3034px 702px #fff,
        2330px 2366px #fff, 730px 2552px #fff, 3037px 2321px #fff,
        634px 21px #fff, 3620px 3471px #fff, 369px 3462px #fff,
        3101px 246px #fff, 1060px 1450px #fff, 919px 2327px #fff,
        2315px 527px #fff, 2364px 1143px #fff, 1710px 2351px #fff,
        138px 2887px #fff, 2793px 2212px #fff, 3130px 1151px #fff,
        2389px 1864px #fff, 1043px 2208px #fff, 1827px 2423px #fff,
        3226px 2075px #fff, 2111px 2775px #fff, 1069px 1058px #fff,
        1802px 170px #fff, 1358px 1409px #fff, 1512px 293px #fff,
        2566px 426px #fff, 3693px 2151px #fff, 1173px 1008px #fff,
        1919px 2668px #fff, 3813px 684px #fff, 2643px 1487px #fff,
        834px 1048px #fff, 930px 87px #fff, 938px 2899px #fff, 732px 3826px #fff,
        2619px 86px #fff, 3481px 453px #fff, 1111px 763px #fff,
        2509px 2198px #fff, 3836px 2066px #fff, 3936px 3196px #fff,
        694px 2011px #fff, 3122px 1874px #fff, 1724px 1889px #fff,
        2360px 3922px #fff, 2590px 2416px #fff, 2683px 3729px #fff,
        832px 767px #fff, 1011px 2555px #fff, 3283px 1759px #fff,
        1618px 744px #fff, 3118px 1467px #fff, 1216px 3695px #fff,
        3490px 616px #fff, 3060px 2156px #fff, 2526px 1136px #fff,
        566px 3235px #fff, 960px 2999px #fff, 539px 485px #fff,
        883px 3048px #fff, 2317px 2740px #fff, 3132px 2617px #fff,
        3893px 532px #fff, 351px 3866px #fff, 1541px 2388px #fff,
        3393px 801px #fff, 2601px 3780px #fff, 806px 1689px #fff,
        2343px 1610px #fff, 1674px 2587px #fff, 824px 1973px #fff,
        976px 113px #fff, 853px 3107px #fff, 1575px 2722px #fff,
        2637px 1166px #fff, 632px 3934px #fff, 1997px 2741px #fff,
        2410px 1357px #fff, 2526px 3899px #fff, 1814px 3033px #fff;
}
#stars3 {
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: 3192px 1958px #fff, 456px 61px #fff, 255px 3975px #fff,
        2868px 3321px #fff, 418px 2308px #fff, 1054px 1195px #fff,
        3477px 1281px #fff, 3204px 3024px #fff, 3595px 1004px #fff,
        2732px 2115px #fff, 3105px 1231px #fff, 663px 3472px #fff,
        3568px 2178px #fff, 35px 3507px #fff, 3063px 2718px #fff,
        1062px 1608px #fff, 2658px 1682px #fff, 2310px 3520px #fff,
        3392px 3334px #fff, 3203px 2921px #fff, 1230px 184px #fff,
        229px 793px #fff, 915px 2037px #fff, 1127px 330px #fff,
        2080px 2551px #fff, 114px 941px #fff, 3796px 1920px #fff,
        2034px 3809px #fff, 1850px 562px #fff, 920px 3790px #fff,
        3417px 2070px #fff, 113px 1005px #fff, 748px 917px #fff,
        3229px 2442px #fff, 2774px 3622px #fff, 3460px 1086px #fff,
        3856px 3611px #fff, 3517px 1032px #fff, 1412px 1040px #fff,
        2636px 2109px #fff, 3071px 2474px #fff, 2710px 2097px #fff,
        2602px 3281px #fff, 3365px 3741px #fff, 2173px 1174px #fff,
        2854px 3066px #fff, 1134px 2397px #fff, 1973px 2246px #fff,
        3653px 2525px #fff, 3123px 1641px #fff, 2353px 1322px #fff,
        1584px 3061px #fff, 2371px 143px #fff, 1693px 1819px #fff,
        290px 3177px #fff, 2592px 1982px #fff, 710px 2311px #fff,
        3796px 531px #fff, 471px 1187px #fff, 518px 2396px #fff,
        3636px 3484px #fff, 2085px 3565px #fff, 909px 2575px #fff,
        1180px 332px #fff, 2597px 832px #fff, 2783px 1093px #fff,
        1498px 2057px #fff, 2252px 762px #fff, 3936px 1847px #fff,
        2695px 2428px #fff, 400px 1607px #fff, 2835px 2232px #fff,
        966px 722px #fff, 1954px 2729px #fff, 2187px 1179px #fff,
        1481px 3495px #fff, 3604px 37px #fff, 3694px 184px #fff,
        3315px 2258px #fff, 1702px 1936px #fff, 1190px 1726px #fff,
        825px 550px #fff, 2720px 1285px #fff, 2620px 1214px #fff,
        2826px 1573px #fff, 774px 1881px #fff, 1591px 355px #fff,
        1607px 2507px #fff, 1534px 3834px #fff, 315px 2568px #fff,
        930px 2014px #fff, 2792px 3174px #fff, 537px 2289px #fff,
        1939px 910px #fff, 891px 1817px #fff, 1745px 757px #fff,
        562px 2247px #fff, 1915px 1824px #fff, 2358px 2096px #fff,
        536px 308px #fff;
    animation: animStar 150s linear infinite;
    position: relative;
    z-index: 2;
}
#stars3:after {
    content: " ";
    position: absolute;
    top: 1000px;
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: 3192px 1958px #fff, 456px 61px #fff, 255px 3975px #fff,
        2868px 3321px #fff, 418px 2308px #fff, 1054px 1195px #fff,
        3477px 1281px #fff, 3204px 3024px #fff, 3595px 1004px #fff,
        2732px 2115px #fff, 3105px 1231px #fff, 663px 3472px #fff,
        3568px 2178px #fff, 35px 3507px #fff, 3063px 2718px #fff,
        1062px 1608px #fff, 2658px 1682px #fff, 2310px 3520px #fff,
        3392px 3334px #fff, 3203px 2921px #fff, 1230px 184px #fff,
        229px 793px #fff, 915px 2037px #fff, 1127px 330px #fff,
        2080px 2551px #fff, 114px 941px #fff, 3796px 1920px #fff,
        2034px 3809px #fff, 1850px 562px #fff, 920px 3790px #fff,
        3417px 2070px #fff, 113px 1005px #fff, 748px 917px #fff,
        3229px 2442px #fff, 2774px 3622px #fff, 3460px 1086px #fff,
        3856px 3611px #fff, 3517px 1032px #fff, 1412px 1040px #fff,
        2636px 2109px #fff, 3071px 2474px #fff, 2710px 2097px #fff,
        2602px 3281px #fff, 3365px 3741px #fff, 2173px 1174px #fff,
        2854px 3066px #fff, 1134px 2397px #fff, 1973px 2246px #fff,
        3653px 2525px #fff, 3123px 1641px #fff, 2353px 1322px #fff,
        1584px 3061px #fff, 2371px 143px #fff, 1693px 1819px #fff,
        290px 3177px #fff, 2592px 1982px #fff, 710px 2311px #fff,
        3796px 531px #fff, 471px 1187px #fff, 518px 2396px #fff,
        3636px 3484px #fff, 2085px 3565px #fff, 909px 2575px #fff,
        1180px 332px #fff, 2597px 832px #fff, 2783px 1093px #fff,
        1498px 2057px #fff, 2252px 762px #fff, 3936px 1847px #fff,
        2695px 2428px #fff, 400px 1607px #fff, 2835px 2232px #fff,
        966px 722px #fff, 1954px 2729px #fff, 2187px 1179px #fff,
        1481px 3495px #fff, 3604px 37px #fff, 3694px 184px #fff,
        3315px 2258px #fff, 1702px 1936px #fff, 1190px 1726px #fff,
        825px 550px #fff, 2720px 1285px #fff, 2620px 1214px #fff,
        2826px 1573px #fff, 774px 1881px #fff, 1591px 355px #fff,
        1607px 2507px #fff, 1534px 3834px #fff, 315px 2568px #fff,
        930px 2014px #fff, 2792px 3174px #fff, 537px 2289px #fff,
        1939px 910px #fff, 891px 1817px #fff, 1745px 757px #fff,
        562px 2247px #fff, 1915px 1824px #fff, 2358px 2096px #fff,
        536px 308px #fff;
}
@keyframes animStar {
    from {
        transform: translateY(0px);
    }
    to {
        transform: translateY(-1000px);
    }
}

`

export default GlobalStyle

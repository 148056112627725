import React, { useState } from 'react'
import styled from 'styled-components'
import { Modal, Text } from '@pancakeswap-libs/uikit'

interface ProviderErrorModalProps {
  onDismiss?: () => void
}

const ModalContent = styled.div`
  margin-bottom: 16px;
`

const ProviderErrorModal: React.FC<ProviderErrorModalProps> = ({ onDismiss }) => {

  return (
    <Modal title="Provider Error" onDismiss={onDismiss}>
      <ModalContent>
        <Text>
          No provider found!<br/><br/>
          Either install <a rel="noreferrer" href="https://metamask.io/" target="_blank"><b><u>MetaMask</u></b></a> browser 
          extension or open <a rel="noreferrer" href="https://app.artifest.world"><b><u>app.artifest.world</u></b></a> application 
          in Trust Wallet mobile application.
        </Text>
      </ModalContent>
    </Modal>
  )
}

export default ProviderErrorModal
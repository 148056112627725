import React, { useState } from 'react'
import { Button, Flex, Modal, Text } from '@pancakeswap-libs/uikit'
import styled from 'styled-components'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import multicall from 'utils/multicall'
import contracts from 'config/constants/contracts'
import useAirdrop  from 'hooks/useAirdrop'
import airdropABI from 'config/abi/airdrop.json'
import BigNumber from 'bignumber.js'
import TokenBalance from './TokenBalance'

interface ClaimModalProps {
  onConfirm?: (amount: string) => void
  onDismiss?: () => void
}

const FlexWithGap = styled(Flex)`
  gap: 12px;
  margin-bottom: 24px;
`

const ClaimModal: React.FC<ClaimModalProps> = ({ onConfirm, onDismiss }) => {
  const { account } = useWallet();
  const { onClaim } = useAirdrop();
  const [isDisabled, setIsDisabled] = useState(false);
  const [checkReward, setCheckReward] = useState('Check Your Claim');
  const [canClaim, setCanClaim] = useState(false);
  const [rewardValue, setRewardValue] = useState(new BigNumber(0));

  const CheckClaim = async() => {

    const [rewardAmountCall, canClaimCall] = await multicall(airdropABI, [
      {
        address: contracts.airdrop[process.env.REACT_APP_CHAIN_ID],
        name: 'rewardAmount',
      },
      {
        address: contracts.airdrop[process.env.REACT_APP_CHAIN_ID],
        name: 'checkCanClaimFor',
        params: [account]
      },
    ])

    if(canClaimCall[0]) {
      setCanClaim(true);
      setRewardValue(new BigNumber(rewardAmountCall).div(new BigNumber(10).pow(18)));
    } else {
      setIsDisabled(true);
      setCheckReward('No claim');
    }
  }

  const onClaimCall = async () => {
    try {
      await onClaim();
      setCanClaim(false);
      setIsDisabled(true);
      setCheckReward('Processed!');
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Modal title="Claim your GOLD tokens" onDismiss={onDismiss}>
      <FlexWithGap alignItems="center">
        <img src="/images/egg/2.png" alt="GOLD token" width="60px"/>
        <Flex flexDirection="column">
          <Text>Your balance:</Text>
          <Text fontSize="24px"><TokenBalance/></Text>
        </Flex>
      </FlexWithGap>
      { canClaim ?
        <Button fullWidth variant="secondary" onClick={onClaimCall}>{`Claim Your ${rewardValue} GOLD Token Now!`}</Button> : 
        <Button fullWidth variant="secondary" disabled={isDisabled} onClick={CheckClaim}>{checkReward}</Button>
      }
    </Modal>
  )
}

export default ClaimModal
import BigNumber from 'bignumber.js'
import erc20 from 'config/abi/erc20.json'
import cakeMasterchefABI from 'config/abi/masterchef.json'
import diamondMasterchefABI from 'config/abi/diamondMasterchef.json'
import multicall from 'utils/multicall'
import { getMasterChefAddress, getDiamondMasterChefAddress } from 'utils/addressHelpers'
import cakeFarmsConfig from 'config/constants/farms'
import diamondFarmsConfig from 'config/constants/diamondFarms'
import { QuoteToken } from '../../config/constants/types'

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID
const cakeMasterChefAddress = getMasterChefAddress();
const diamondMasterChefAddress = getDiamondMasterChefAddress();

const fetchFarms = async () => fetchFarmsData(cakeFarmsConfig, cakeMasterChefAddress, cakeMasterchefABI, "GOLD")
export const fetchDimondFarms = async () => fetchFarmsData(diamondFarmsConfig, diamondMasterChefAddress, diamondMasterchefABI, "DIAMOND")

const fetchFarmsData = async (farmsConfig, masterChefAddress, masterchefABI, farm) => {
  const farmsCall = [];
  const masterchefABICalls = []

  await farmsConfig.forEach((farmConfig) => {
    const lpAdress = farmConfig.lpAddresses[CHAIN_ID]
    farmsCall.push(
      // Balance of token in the LP contract
      {
        address: farmConfig.tokenAddresses[CHAIN_ID],
        name: 'balanceOf',
        params: [lpAdress],
      },
      // Balance of quote token on LP contract
      {
        address: farmConfig.quoteTokenAdresses[CHAIN_ID],
        name: 'balanceOf',
        params: [lpAdress],
      },
      // Balance of LP tokens in the master chef contract
      {
        address: farmConfig.isTokenOnly ? farmConfig.tokenAddresses[CHAIN_ID] : lpAdress,
        name: 'balanceOf',
        params: [masterChefAddress],
      },
      // Total supply of LP tokens
      {
        address: lpAdress,
        name: 'totalSupply',
      },
      // Token decimals
      {
        address: farmConfig.tokenAddresses[CHAIN_ID],
        name: 'decimals',
      },
      // Quote token decimals
      {
        address: farmConfig.quoteTokenAdresses[CHAIN_ID],
        name: 'decimals',
      },
    )
    masterchefABICalls.push(
      {
        address: masterChefAddress,
        name: 'poolInfo',
        params: [farmConfig.pid],
      },
      {
        address: masterChefAddress,
        name: 'totalAllocPoint',
      },
      {
        address: masterChefAddress,
        name: `${farm.toLowerCase()}PerBlock`,
      }
    )
  });

  const erc20Res = await multicall(erc20, farmsCall) || []
  const masterchefRes = await multicall(masterchefABI, masterchefABICalls) || []

  if(erc20Res.length === 0 || masterchefRes.length === 0 ) return undefined;

  const data = await Promise.all(
    farmsConfig.map(async (farmConfig, index) => {
      const erc20Index = index * 6;
      const [
        tokenBalanceLP,
        quoteTokenBlanceLP,
        lpTokenBalanceMC,
        lpTotalSupply,
        tokenDecimals,
        quoteTokenDecimals
      ] = erc20Res.slice(erc20Index, erc20Index + 6);

      let tokenAmount;
      let lpTotalInQuoteToken;
      let tokenPriceVsQuote;

      if (farmConfig.isTokenOnly) {
        tokenAmount = new BigNumber(lpTokenBalanceMC).div(new BigNumber(10).pow(6));
        if(farmConfig.tokenSymbol === QuoteToken.BUSD && farmConfig.quoteTokenSymbol === QuoteToken.BUSD){
          tokenPriceVsQuote = new BigNumber(1);
        } else {
          tokenPriceVsQuote = new BigNumber(quoteTokenBlanceLP).div(new BigNumber(tokenBalanceLP));
        }
        lpTotalInQuoteToken = tokenAmount.times(tokenPriceVsQuote);
      } else {
        const lpTokenRatio = new BigNumber(lpTokenBalanceMC).div(new BigNumber(lpTotalSupply))
        lpTotalInQuoteToken = new BigNumber(quoteTokenBlanceLP)
          .div(new BigNumber(10).pow(6))
          .times(new BigNumber(2))
          .times(lpTokenRatio)

        tokenAmount = new BigNumber(tokenBalanceLP).div(new BigNumber(10).pow(tokenDecimals)).times(lpTokenRatio)
        const quoteTokenAmount = new BigNumber(quoteTokenBlanceLP)
          .div(new BigNumber(10).pow(quoteTokenDecimals))
          .times(lpTokenRatio)

        if(tokenAmount.comparedTo(0) > 0){
          tokenPriceVsQuote = quoteTokenAmount.div(tokenAmount);
        } else {
          tokenPriceVsQuote = new BigNumber(quoteTokenBlanceLP).div(new BigNumber(tokenBalanceLP));
        }
      }

      const masterchefIndex = index * 3;
      const [info, totalAllocPoint, coinPerBlock] = masterchefRes.slice(masterchefIndex, masterchefIndex + 3);

      const harvestLockup = Math.floor(new BigNumber(info.harvestInterval._hex).toNumber() / 3600)
      const allocPoint = new BigNumber(info.allocPoint._hex)
      const poolWeight = allocPoint.div(new BigNumber(totalAllocPoint))

      return {
        ...farmConfig,
        tokenAmount: tokenAmount.toJSON(),
        lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
        tokenPriceVsQuote: tokenPriceVsQuote.toJSON(),
        poolWeight: poolWeight.toNumber(),
        multiplier: `${allocPoint.div(100).toString()}X`,
        depositFeeBP: info.depositFeeBP,
        goldPerBlock: farm === 'GOLD' ? new BigNumber(coinPerBlock).toNumber() : 0,
        diamondPerBlock: farm === 'DIAMOND' ? new BigNumber(coinPerBlock).toNumber() : 0,
        harvestLockup,
        farm,
      }
    }),
  )
  return data
}

export default fetchFarms

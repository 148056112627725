import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 15,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'GOLD - WMATIC - QuickSwap',
    lpAddresses: {
      97: '',
      56: '',
      137: '0x37b0b77C1b65996aE013B12d85ae383B9D6F62cc',
    },
    tokenSymbol: 'GOLD',
    tokenAddresses: {
      97: '',
      56: '',
      137: '0x7D41E0D59149F018D0D5B93F44B65f8ae0b90d6d',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 1,
    risk: 3,
    lpSymbol: 'WMATIC - USDC',
    lpAddresses: {
      97: '',
      56: '0x6e7a5fafcec6bb1e78bae2a1f0b612012bf14827',
      137: '0x6e7a5fafcec6bb1e78bae2a1f0b612012bf14827', // WMATIC - USDC
    },
    tokenSymbol: 'WMATIC',
    tokenAddresses: {
      97: '',
      56: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
      137: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270', // WMATIC
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 2,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'WETH - USDC',
    lpAddresses: {
      97: '',
      56: '0x19e7cbecdd23a16dfa5573df54d98f7caae03019',
      137: '0x853ee4b2a13f8a742d64c8f088be7ba2131f670d',
    },
    tokenSymbol: 'WETH',
    tokenAddresses: {
      97: '',
      56: '0xf952fc3ca7325cc27d15885d37117676d25bfda6',
      137: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 16,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'GOLD',
    lpAddresses: {
      97: '',
      56: '',
      137: '0x37b0b77C1b65996aE013B12d85ae383B9D6F62cc', // GOLD
    },
    tokenSymbol: 'GOLD',
    tokenAddresses: {
      97: '',
      56: '',
      137: '0x7D41E0D59149F018D0D5B93F44B65f8ae0b90d6d',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 17,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'WMATIC',
    lpAddresses: {
      97: '',
      56: '',
      137: '0x6e7a5fafcec6bb1e78bae2a1f0b612012bf14827', // WMATIC - USDC > LP
    },
    tokenSymbol: 'WMATIC',
    tokenAddresses: {
      97: '',
      56: '',
      137: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 18,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'USDC',
    lpAddresses: {
      97: '',
      56: '',
      137: '0x2cf7252e74036d1da831d11089d326296e64a728', // USDC - USDC
    },
    tokenSymbol: 'USDC',
    tokenAddresses: {
      97: '',
      56: '',
      137: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 19,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'USDT',
    lpAddresses: {
      97: '',
      56: '',
      137: '0x2cf7252e74036d1da831d11089d326296e64a728', // USDT - USDC
    },
    tokenSymbol: 'USDT',
    tokenAddresses: {
      97: '',
      56: '',
      137: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 20,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'WETH',
    lpAddresses: {
      97: '',
      56: '',
      137: '0x853ee4b2a13f8a742d64c8f088be7ba2131f670d', // ETH - USDC
    },
    tokenSymbol: 'WETH',
    tokenAddresses: {
      97: '',
      56: '',
      137: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 21,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'WBTC',
    lpAddresses: {
      97: '',
      56: '',
      137: '0xf6a637525402643b0654a54bead2cb9a83c8b498', // WBTC - USDC
    },
    tokenSymbol: 'WBTC',
    tokenAddresses: {
      97: '',
      56: '',
      137: '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 22,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'AAVE',
    lpAddresses: {
      97: '',
      56: '',
      137: '0x0554059d42e26f35cc958581c71fdfd92405d02f', // AAVE - USDC
    },
    tokenSymbol: 'AAVE',
    tokenAddresses: {
      97: '',
      56: '',
      137: '0xd6df932a45c0f255f85145f286ea0b292b21c90b',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 23,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'GOLD - WMATIC - DFYN',
    lpAddresses: {
      97: '',
      56: '',
      137: '0x754FC8B0291337bfd7F936C83B4fb02f6655158E',
    },
    tokenSymbol: 'GOLD',
    tokenAddresses: {
      97: '',
      56: '',
      137: '0x7D41E0D59149F018D0D5B93F44B65f8ae0b90d6d',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    exchange: 'DFYN'
  },
  {
    pid: 24,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'GOLD - WMATIC - SUSHI',
    lpAddresses: {
      97: '',
      56: '',
      137: '0x3B2EAdb53518dF85B311AFc4fED7Cd463b9eaca7',
    },
    tokenSymbol: 'GOLD',
    tokenAddresses: {
      97: '',
      56: '',
      137: '0x7D41E0D59149F018D0D5B93F44B65f8ae0b90d6d',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    exchange: 'SUSHI'
  },
  {
    pid: 25,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'GOLD - USDC - QuickSwap',
    lpAddresses: {
      97: '',
      56: '',
      137: '0x61f6677cbAbB8D3F75455844628B0c816FFCA846',
    },
    tokenSymbol: 'GOLD',
    tokenAddresses: {
      97: '',
      56: '',
      137: '0x7D41E0D59149F018D0D5B93F44B65f8ae0b90d6d',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 26,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'GOLD - USDC - DFYN',
    lpAddresses: {
      97: '',
      56: '',
      137: '0x3D6CD317ca7Fa94D3745Fab85931EE0730cF488e',
    },
    tokenSymbol: 'GOLD',
    tokenAddresses: {
      97: '',
      56: '',
      137: '0x7D41E0D59149F018D0D5B93F44B65f8ae0b90d6d',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    exchange: 'DFYN'
  },
  {
    pid: 27,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'GOLD - USDC - SUSHI',
    lpAddresses: {
      97: '',
      56: '',
      137: '0xB84942793D38eCb7399602c2027B31A891048f75',
    },
    tokenSymbol: 'GOLD',
    tokenAddresses: {
      97: '',
      56: '',
      137: '0x7D41E0D59149F018D0D5B93F44B65f8ae0b90d6d',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    exchange: 'SUSHI'
  },
]

export default farms

import React, { useEffect, useState } from 'react'
import { Button, Login, useModal, UserBlock } from '@pancakeswap-libs/uikit'
import styled from 'styled-components'
import ProviderErrorModal from 'components/Menu/ProviderErrorModal'
import ChainErrorModal from 'components/Menu/ChainErrorModal'
import Container from './Container'

export interface Props {
  account?: string;
  login: Login;
  logout: () => void;
  error?: any;
}

const Navbar = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: ${({ theme }) => theme.colors.card};
  padding: 12px 0;
  z-index: 1;
  box-shadow: 0 0 20px #000;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Brand = styled.a`
  display: inline-block;
  img {
    max-height: 46px;
    max-width: 100%;
  }
`

const Nav: React.FC<Props> = ({account, login, logout, error }) => {
  const [ isInvalidBrowser, setInvalidBrowser] = useState(false)
  const [ unsupportedChain, setUnsupportedChain] = useState(false)

  const [showProviderErrorModal] = useModal(
    <ProviderErrorModal />
  )

  const [showUnsupportedChainModal] = useModal(
    <ChainErrorModal />
  )

  useEffect(()=>{
    if (!account && error) {
      if(error.name === 't' && !isInvalidBrowser) {
        showProviderErrorModal();
        setInvalidBrowser(true);
      }
      if(error.name === 'ChainUnsupportedError' && !unsupportedChain) {
        showUnsupportedChainModal();
        setUnsupportedChain(true);
      }
    } else {
      setInvalidBrowser(false);
      setUnsupportedChain(false);
    }
  }, [account, error, isInvalidBrowser, unsupportedChain, showProviderErrorModal, showUnsupportedChainModal])

  return(
    <Navbar>
      <Container>
        <Wrapper>
          <Brand href="/">
            <img src="/logo.png" alt="Artifest"/>
          </Brand>
          <UserBlock account={account}
            login={login}
            logout={logout}/>
        </Wrapper>
      </Container>
    </Navbar>
  );
};

export default Nav
export default {
  cake: {
    56: '0xF952Fc3ca7325Cc27D15885d37117676d25BfdA6',
    97: '',
    137: '0x7D41E0D59149F018D0D5B93F44B65f8ae0b90d6d', // GOLD
  },
  masterChef: {
    56: '0xe70E9185F5ea7Ba3C5d63705784D8563017f2E57',
    97: '',
    137: '0x8c18b6e5ED35Ec3Bfde9fbd6fF62a518E2221a44' 
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '',
    137: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270' // WMATIC
  },
  lottery: {
    56: '',
    97: '',
  },
  lotteryNFT: {
    56: '',
    97: '',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
    137: '0x6DDD85D8BcAf2872F631e373aA206904145a7576',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '',
    137: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174' // USDC
  },
  usdt: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '',
    137: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F' // USDT
  },
  weth: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '',
    137: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619' // WETH
  },
  forest: {
    56: '',
    97: '',
    137: '0x4b17699c4990265D35875C15D5377571159f6bfd'
  },
  airdrop: {
    56: '',
    97: '',
    137: '0x17bfF653Ef64BC5FaBD14FFfCe68246656dD0554'
  },
  nfts:{
    97: '',
    56: '',
    137: '0xFFEFAdb9B5D90a1a6E76bc1BFa734ef942DAE19b',
    80001: '0x7129abd9b7172577291681eff67c88a7c13e9d4a',
  },
  diamond: {
    56: '',
    97: '',
    137: '0xe97Cf7B2F979b21Eed14db8460d1731aad955cf4', // Diamond
  },
  diamondmasterChef: {
    56: '',
    97: '',
    137: '0x701D407d2356B76fF18598F07Cc03F84F6Ce14Ff', // Diamond Masterchef
  },
}

import { useContext } from 'react'
import { ThemeContext as StyledThemeCopntext } from 'styled-components'
import { ThemeContext } from 'contexts/ThemeContext'

const useTheme = () => {
  const { isDark, toggleTheme } = useContext(ThemeContext)
  const theme = useContext(StyledThemeCopntext)

  const primaryColor = '#04f6f9'
  const dark = '#000'
  const light = '#fff'

  theme.colors.text = light
  theme.colors.secondary = primaryColor
  theme.colors.textSubtle = primaryColor
  theme.colors.overlay = primaryColor
  theme.colors.primary = primaryColor
  theme.colors.primaryBright = primaryColor
  theme.colors.background = dark
  theme.button.subtle.background = primaryColor
  theme.button.primary.background = primaryColor
  theme.button.primary.color = dark
  theme.button.primary.backgroundActive = primaryColor
  theme.button.primary.backgroundHover = primaryColor
  theme.button.secondary.border = `2px solid ${primaryColor}`
  theme.button.secondary.borderColorHover = primaryColor
  theme.button.secondary.color = primaryColor
  theme.button.tertiary.color = primaryColor
  theme.button.text.color = primaryColor

  return { isDark, toggleTheme, theme }
}

export default useTheme

import React, { useContext } from 'react'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import { allLanguages } from 'config/localisation/languageCodes'
import { LanguageContext } from 'contexts/Localisation/languageContext'
import useTheme from 'hooks/useTheme'
import { usePriceCakeBusd } from 'state/hooks'
import { Menu as UikitMenu } from '@pancakeswap-libs/uikit'
import styled from 'styled-components'
import contracts from 'config/constants/contracts'
import Nav from 'components/layout/Nav'
import ClaimToken from './ClaimToken'
import config from './config'


const Wrapper = styled.div`
  padding-top: 80px;
`
const Menu = (props) => {
  const { account, connect, reset, error } = useWallet()
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext)
  const { isDark, toggleTheme } = useTheme()
  const cakePriceUsd = usePriceCakeBusd()
  const loadCustomLayout = true
  return (
    <Wrapper>
      <div id="stars">&nbsp;</div>
      <div id="stars2">&nbsp;</div>
      <div id="stars3">&nbsp;</div>
      <Nav account={account}
        login={connect}
        logout={reset}
        error={error}
        />
      <UikitMenu
        account={account}
        login={connect}
        logout={reset}
        isDark={isDark}
        toggleTheme={toggleTheme}
        currentLang={selectedLanguage && selectedLanguage.code}
        langs={allLanguages}
        setLang={setSelectedLanguage}
        cakePriceUsd={cakePriceUsd}
        links={config}
        priceLink={`https://polygonscan.com/token/${contracts.cake[process.env.REACT_APP_CHAIN_ID]}`}
        {...props}
        customLayout={loadCustomLayout}
        customNavLinks={<ClaimToken/>} 
      />
    </Wrapper>
  )
}

export default Menu

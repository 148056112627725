import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const diamondFarms: FarmConfig[] = [
  {
    pid: 0,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'GOLD',
    lpAddresses: {
      97: '',
      56: '',
      137: '0x7D41E0D59149F018D0D5B93F44B65f8ae0b90d6d',
    },
    tokenSymbol: 'DIAMOND',
    tokenAddresses: {
      97: '',
      56: '',
      137: '0x7D41E0D59149F018D0D5B93F44B65f8ae0b90d6d',
    },
    quoteTokenSymbol: QuoteToken.CAKE,
    quoteTokenAdresses: contracts.cake,
  },
]

export default diamondFarms
